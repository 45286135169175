/*!

 =========================================================
 * Material Dashboard React - v1.4.1 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

html {
  overflow-y: hidden;
}

html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    background-color: #fff;
    color: #3C4858;
    margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.5em;
}

textarea::-webkit-scrollbar, #liveChatArea::-webkit-scrollbar {
  width: 3px;
}

textarea, #liveChatArea {
  scrollbar-width: thin;
  scrollbar-color: darkgrey;
}

textarea::-webkit-scrollbar-track, #liveChatArea::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.0);
}

textarea::-webkit-scrollbar-thumb, #liveChatArea::-webkit-scrollbar-thumb {
background-color: darkgrey;
outline: transparent;
}

#dashboard-pass-fail-chart .ct-chart .ct-legend .ct-series-0:before {
  background-color: #33AF58;
  border-color: #33AF58;
}

#dashboard-pass-fail-chart .ct-chart .ct-legend .ct-series-1:before {
  background-color: #F34335;
  border-color: #F34335;
}

#dashboard-pass-fail-chart .ct-chart .ct-legend .ct-series-0 {
  color: #33AF58;
}

#dashboard-pass-fail-chart .ct-chart .ct-legend .ct-series-1 {
  color: #F34335;
}


.chartist-tooltip {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 12px;
  background-color: #eee;
  border-radius: 5px;
}

.chartist-tooltip:before {
  border-top-color: #eee;
}

.rs-picker-menu {
  position: absolute;
  z-index: 9999;
  padding: 10px;
  list-style: none;
  background-color: white;
  border-radius: 6px;
  -webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.36);
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.36);
  margin: 0px;
  min-width: 260px;
  font-weight: 400;
}

.rs-picker-menu ul {
  padding: 0px!important;
  list-style: none;
  margin: 3px 0 0 0;
}

.rs-picker-menu ul li {
  padding: 6px 18px!important;
  margin: 0px;
  cursor: pointer;
  font-size: 13px;
  border-radius: 6px;
  margin: 0px;
  text-align: left;
}

.rs-picker-menu ul li:hover {
  background: #FF980066;
}


.rs-picker-menu ul li a {
  color: #2B3E53;
}

.introjs-tooltiptext {
  font-size: 12px;
}


input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  /* width: 100%; */ /* Specific width is required for Firefox. */
  /* background: transparent; */ /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  outline: 0;
  box-sizing: border-box;
  margin-top: -8px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: #fff;
  border: 3px solid #00acc1;
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  width: 12px;
height: 12px;
display: flex;
outline: 0;
position: absolute;
box-sizing: border-box;
margin-top: -6px;
transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
align-items: center;
margin-left: -6px;
border-radius: 50%;
justify-content: center;
background-color: #2A3F54;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  width: 12px;
height: 12px;
display: flex;
outline: 0;
position: absolute;
box-sizing: border-box;
margin-top: -6px;
transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
align-items: center;
margin-left: -8px;
border-radius: 50%;
justify-content: center;
background-color: #fff;
border: 3px solid #00acc1;
}

input[type=range]::-webkit-slider-runnable-track {
      height: 5px;
      display: block;
      border-radius: 5px;
      /*background-color: rgba(0, 172, 193, 0.5);*/
}

/*input[type=range]:focus::-webkit-slider-runnable-track {
   background: rgba(0, 172, 193, 0.7);
}*/

input[type=range]::-moz-range-track {
    height: 2px;
    display: block;
    border-radius: 1px;
    background-color: #a1caf3;
}

input[type=range]::-ms-track {
    height: 2px;
    display: block;
    border-radius: 1px;
    background-color: #a1caf3;
}
input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type=range]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]:focus::-ms-fill-upper {
  background: #367ebd;
}

.introjs-fix {
  right:10% !important;
  bottom: 40% !important;
}

.simplebar-content {
  overflow: auto !important;
}

blockquote footer:before, blockquote small:before {
    content: '\2014 \00A0';
}

small {
  font-size: 80%;
}

h1 {
    font-size: 3em;
    line-height: 1.15em;
}

h2 {
    font-size: 2.4em;
}

h3 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4em;
    margin: 20px 0 10px;
}

h4 {
    font-size: 15px;
    line-height: 1.4em;
}

h5 {
    font-size: 1.25em;
    line-height: 1.4em;
    margin-bottom: 15px;
}

h6 {
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 500;
}

body {
    background-color: #fff;
    color: #2B3E53;
}

blockquote p {
    font-style: italic;
}

body, h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.5em;
}

a {
    color: #FF9800;
    text-decoration: none;
}

a:hover, a:focus {
    color: #FF9800;
    text-decoration: none;
}

legend {
    border-bottom: 0;
}

* {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
}

*:focus {
    outline: 0;
}

a:focus, a:active,
button:active, button:focus, button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    outline: 0 !important;
}

legend {
    margin-bottom: 20px;
    font-size: 21px;
}

output {
    padding-top: 8px;
    font-size: 14px;
    line-height: 1.42857;
}

label {
    font-size: 14px;
    line-height: 1.42857;
    color: #AAAAAA;
    font-weight: 400;
}

footer {
    padding: 15px 0;
}

footer ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
}

footer ul li {
    display: inline-block;
}

footer ul li a {
    color: inherit;
    padding: 15px;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 3px;
    text-decoration: none;
    position: relative;
    display: block;
}

footer ul li a:hover {
    text-decoration: none;
}

@media (max-width: 991px) {
    body,
    html {
        position: relative;
        overflow-x: hidden;
    }

    #bodyClick {
        height: 100%;
        width: 100%;
        position: fixed;
        opacity: 0;
        top: 0;
        left: auto;
        right: 260px;
        content: "";
        z-index: 9999;
        overflow-x: hidden;
    }
}

.CodeMirror {
    border-color: #EEEEEE;
    border-width: 1px;
    border-style: solid;
    height: 450px
}

.CodeMirror .syntax-error {
    text-decoration: underline dotted red;
}

.Capabilities .CodeMirror {
    height: 200px
}

.CapabilitiesShort .CodeMirror {
    height: 130px
}

.ExpectedActual .CodeMirror {
    height: 250px
}

.Capabilities .CodeMirror {
  height: 200px
}

.ConvoEditor .CodeMirror {
  height: 200px
}

.introjs-helperLayer {
    opacity: 0.5;
    box-shadow: 0 0 0 9999px rgba(1, 1, 1, 0.8);
}
.introjs-tooltip {
    min-width: 600px;
    max-width: 600px;
    padding: 24px;
}
.introjs-tooltipbuttons {
  margin-top: 1em;
}
.introjs-overlay {
    opacity: 0.3 !important;
}
.introjs-helperNumberLayer {
  background: #f44336 !important;
  margin-left: 25px;
}
.introjs-button {
  background-color: #999;
  background-image: none;
  color: #fff !important;
  text-shadow: none;
  font: inherit;
  text-transform: uppercase;
  border: none;
  margin: .3125rem 1px;
  cursor: pointer;
  padding: 12px 30px;
  position: relative;
  min-width: auto;
  font-size: 12px;
  min-height: auto;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
  font-weight: 400;
  will-change: box-shadow, transform;
  line-height: 1.42857143;
  white-space: nowrap;
  touch-action: manipulation;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0;
  vertical-align: middle;
  background-color: #999999;
}

.introjs-button.introjs-skipbutton {
  margin-right: 1em;
}

.introjs-button:hover {
  color: #fff;
}

.introjs-button:focus, .introjs-button:active {
  background-image:none;
}

.introjs-disabled, .introjs-disabled:hover, .introjs-disabled:focus {
  background-color: #bcbcbc !important;
}

.ac-adaptiveCard {
  background-color: transparent!important;
  padding: '0!Important',
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
  }
  
  @page {
    margin: 20mm 12mm 20mm 12mm;
    size: A3;
  }
}